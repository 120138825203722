<template>
   <div class="plate-title"><slot></slot></div>
</template>

<script>
export default {
   name: "PlateTitle",
};
</script>

<style lang="scss" scoped>
.plate-title {
   margin: 30px 0 20px;
   font-size: 24px;
   font-weight: 600;
   color: var(--color-title);
   line-height: 1;

   &:first-child {
      margin-top: 0;
   }
}
</style>
