import axios from "axios";
import qs from "qs";
import { Message, Loading } from "element-ui";

const baseURL = process.env.VUE_APP_PROXY || process.env.VUE_APP_HOST;

// 加载效果
let loadingId = null;

// 显示加载
function showLoading(show) {
   if (show) {
      loadingId = Loading.service({
         fullscreen: true,
         body: true,
      });
   }
}

// 关闭加载
function closeLoading() {
   if (loadingId) {
      loadingId.close();
      loadingId = null;
   }
}

const http = axios.create({
   // 基础路径
   baseURL,
   // 超时
   timeout: 12000,
   headers: {
      "X-Requested-With": "XMLHttpRequest",
   },

   // 格式化请求数据
   transformRequest: [
      (data) => {
         // 没有数据或数据为
         if (!data) {
            return qs.stringify({});
         }

         // formData
         if (data instanceof FormData) {
            return data;
         }

         return qs.stringify(data);
      },
   ],
});

// 请求拦截
http.interceptors.request.use(
   (config) => {
      const { loading } = config;

      showLoading(loading);

      return config;
   },
   (error) => {
      closeLoading();
      return Promise.reject(error);
   },
);

// 返回拦截(按照接口返回风格可能需要修改)
http.interceptors.response.use(
   ({ data, config }) => {
      closeLoading();

      // 接口返回数据正常
      if (data.status === 200) {
         if (config.message) {
            Message({ type: "error", message: data.msg });
         }

         return data;
      }

      // 接口返回异常
      if (data.msg) {
         Message({ type: "error", message: data.msg });
      }

      return Promise.reject(data);
   },
   ({ message }) => {
      closeLoading();
      Message({ type: "error", message: message || "请求超时或服务器错误!" });

      return Promise.reject();
   },
);

export { http };
