<template>
   <div v-loading="loading" class="new" :style="{ minHeight }">
      <template v-if="list.length > 0">
         <com-new-item
            v-for="(item, index) in list"
            :key="index"
            :title="item.title"
            :date="item.date"
            :prefix="
               prefix
                  ? prefix
                  : type && type_uri != item.category.alias
                  ? item.category.title
                  : ''
            "
            :prefix_uri="
               prefix_uri
                  ? prefix_uri
                  : type && type_uri != item.category.alias
                  ? '/lists/?type=' + item.category.alias
                  : ''
            "
            :size="size"
            :show-date="showDate"
            :notice-icon="noticeIcon"
            :is_external="item.external_url"
            :path="`/article?id=${item.id}`"
         ></com-new-item>
      </template>

      <!-- 没有数据 -->
      <div v-else class="new-empty">{{ emptyText }}</div>

      <!-- 分页 -->
      <div v-if="pagination && total > pageSize" class="new-pagination">
         <el-pagination
            hide-on-single-page
            :current-page.sync="pageCurrent"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="getData(pageCurrent)"
         ></el-pagination>
      </div>
   </div>
</template>

<script>
import { http } from "@/http";
import ComNewItem from "@/components/NewItem";

import { Pagination } from "element-ui";

export default {
   name: "ComNewList",
   components: {
      ComNewItem,
      ElPagination: Pagination,
   },
   props: {
      url: {
         type: String,
         required: true,
      },
      gap: {
         type: String,
         default: "small",
      },
      manual_type: {
         type: String,
         default: "",
      },
      // 空提示
      emptyText: {
         type: String,
         default: "- 暂无数据 -",
      },
      // 最低高度
      minHeight: {
         type: String,
         default: "200px",
      },
      // 大小
      size: {
         type: String,
         default: "small",
      },
      // 前缀
      prefix: {
         type: String,
         default: "",
      },
      // 前缀uri
      prefix_uri: {
         type: String,
         default: "",
      },
      // 使用分页
      pagination: Boolean,
      // 分页大小
      pageSize: {
         type: Number,
         default: 15,
      },
      // 当前页面
      pageCurrent: {
         type: Number,
         default: 1,
      },
      // 显示date
      showDate: Boolean,
      // 通知图标
      noticeIcon: Boolean,
   },
   data() {
      return {
         // 数据
         list: [],
         // 加载
         loading: false,
         // 总数
         total: 0,
         type: this.$route.query.type || this.manual_type,
         type_uri: this.$route.name === "lists" ? this.$route.query.type : "",
      };
   },
   watch: {
      $route(newValue) {
         this.pageCurrent = 1;
         console.log(this.pageCurrent);

         if (newValue.name === "search") {
            this.type = "search";
            this.type_uri = "k=aa";
         } else {
            this.type = newValue.name === "lists" ? newValue.query.type : "";
            this.type_uri =
               newValue.name === "lists" ? newValue.query.type : "";
         }
      },
   },
   mounted() {
      if (this.$route.name === "search") {
         this.type = "search";
         this.type_uri = "k=aa";
      }
      this.getData();
   },
   methods: {
      /**
       * 获取数据
       */
      getData(pageCurrent) {
         const vm = this;

         const params = { p: pageCurrent, limit: this.pageSize };
         this.loading = true;
         http
            .get(this.url, { params })
            .then((res) => {
               this.list = [];
               const {
                  data: { data, total },
               } = res;
               
               if (this.type === "search") {
                  data.forEach((element) => {
                     const keywork = vm.$route.params.k;
                     const reg = new RegExp(keywork, "gi");
                     element.title = element.title.replace(
                        reg,
                        `<strong style="color: red;">${keywork}</strong>`,
                     );
                     this.list.push(element);
                  });
               } else {
                  this.list = data;
               }
               this.total = total;
            })
            .then(() => {
               this.loading = false;
            });
      },
   },
};
</script>

<style lang="scss" scoped>
.new {
   &-empty {
      padding: 10% 0;
      font-size: 16px;
      color: var(--color-fun);
      text-align: center;
   }

   &-item__link {
      strong {
         color: var(--color-main);
      }
   }

   &-pagination {
      margin-top: 20px;
      text-align: center;

      :deep(.el-pager li) {
         &.active,
         &:hover {
            color: var(--color-main);
         }
      }
   }
}
</style>
