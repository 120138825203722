import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// NProgress配置
NProgress.configure({
   showSpinner: false,
});

Vue.use(VueRouter);

const router = new VueRouter({
   mode: "hash",
   base: process.env.BASE_URL,
   routes,
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition;
      }

      // 如果是同级路由跳转
      const toParent = to.matched[to.matched.length - 2];
      const fromParent = from.matched[from.matched.length - 2];

      if (to.path !== from.path && toParent === fromParent) {
         return {};
      }

      return { x: 0, y: 0 };
   },
});

// 路由前置钩子
router.beforeEach((to, from, next) => {
   NProgress.start();

   next();
});

// 路由后置钩子
router.afterEach(() => {
   NProgress.done();
});

export default router;
