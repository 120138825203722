<template>
   <div class="banner" @mouseenter="paused = true" @mouseleave="paused = false">
      <img class="banner-bg" :src="src[0]" />

      <template v-for="(item, index) in src">
         <transition :key="item" name="fade">
            <img v-if="index === active" class="banner-img" :src="item" />
         </transition>
      </template>

      <div v-if="src.length > 1" class="banner-dots">
         <span
            v-for="(item, index) in src.length"
            :key="item"
            class="banner-dots__item"
            :class="{ active: index === active }"
            @click="active = index"
         ></span>
      </div>
   </div>
</template>

<script>
// 定时器
let interval = null;

export default {
   name: "ComHeadBanner",
   props: {
      src: {
         type: Array,
         default: () => [],
         requied: true,
      },
   },
   data() {
      return {
         // 激活位置
         active: 0,
         // 轮播间隔
         interval: 6000,
         // 暂停
         paused: false,
      };
   },
   created() {
      if (this.src.length > 0) {
         this.start();
      }
   },
   destroyed() {
      this.clear();
   },
   methods: {
      /**
       * 开始
       */
      start() {
         interval = setInterval(() => {
            if (!this.paused) {
               this.active = (this.active + 1) % this.src.length;
            }
         }, this.interval);
      },
      /**
       * 清除
       */
      clear() {
         window.clearInterval(interval);
         interval = null;
      },
   },
};
</script>

<style lang="scss" scoped>
.banner {
   position: relative;
   max-width: 1920px;
   margin: 20px auto 0;
   background: center / auto 100% no-repeat;

   &-bg {
      display: block;
      width: 100%;
      visibility: hidden;
   }

   &-img {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
   }

   &-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      z-index: 100;
      text-align: center;

      &__item {
         display: inline-block;
         width: 8px;
         height: 8px;
         margin: 0 5px;
         vertical-align: top;
         border-radius: 10px;
         cursor: pointer;
         background: rgba(255, 255, 255, 0.8);

         &.active {
            cursor: default;
            background: var(--color-main);
         }
      }
   }
}
.fade-enter-active,
.fade-leave-active {
   transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
   opacity: 0;
}
</style>
