<template>
   <div class="layout">
      <com-container>
         <div class="layout-tool">
            <div class="layout-menu">
               <el-input
                  v-model="k"
                  size="small"
                  placeholder="搜索内容"
                  v-on:keyup.enter.native="btnSearch"
               >
                  <i
                     slot="suffix"
                     class="el-input__icon el-icon-search"
                     v-on:click="btnSearch"
                  ></i>
               </el-input>
            </div>
         </div>
         <div class="layout-head">
            <router-link class="layout-logo" to="/index"></router-link>
            <div class="layout-nav">
               <div class="layout-nav__item">
                  <router-link class="layout-nav__link" to="/index">
                     首页
                  </router-link>
               </div>
               <div class="layout-nav__item">
                  <router-link class="layout-nav__link" to="/company">
                     企业概况
                  </router-link>
               </div>
               <div class="layout-nav__item">
                  <router-link class="layout-nav__link" to="/party">
                     党建动态
                  </router-link>
               </div>
               <div class="layout-nav__item">
                  <router-link class="layout-nav__link" to="/duty">
                     社会责任
                  </router-link>
               </div>
               <div class="layout-nav__item">
                  <router-link class="layout-nav__link" to="/tobacco">
                     烟草服务
                  </router-link>
               </div>
            </div>
         </div>
      </com-container>

      <div class="layout-body">
         <router-view></router-view>
      </div>

      <div class="layout-bottom">
         <com-container>
            <div class="layout-bottom__warn">
               警告：本站点含有烟草内容，十八岁以下请勿浏览
            </div>
            <div class="layout-link">
               <a
                  class="layout-link__item"
                  href="http://www.tobacco.gov.cn/"
                  target="_blank"
               >
                  <img class="layout-link__img" src="./imgs/link_01.png" />
               </a>
               <router-link class="layout-link__item" to="/features">
                  <img class="layout-link__img" src="./imgs/link_02.png" />
               </router-link>
               <router-link class="layout-link__item" to="/map">
                  <img class="layout-link__img" src="./imgs/link_03.png" />
               </router-link>
            </div>
            <div class="layout-bottom__des">
               <p>版权所有 湖南中烟工业有限责任公司www.hntic.com</p>
               <a href="https://beian.miit.gov.cn/" target="_blank">
                  ICP备案号：湘ICP备08002084号-1,-3
               </a>
               <a
                  href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43011102002356"
                  target="_blank"
               >
                  <img src="./imgs/jgawb.png" alt="" />
                  湘公网安备：43011102002356号
               </a>
            </div>
            <div class="layout-bottom__count">
               当前浏览量为：{{ browseCount }}
            </div>
         </com-container>
      </div>
   </div>
</template>

<script>
import { http } from "@/http";
import ComContainer from "@/components/Container";
import { Input } from "element-ui";

export default {
   name: "LayOut",
   components: {
      ComContainer,
      [Input.name]: Input,
   },
   data() {
      return {
         k: "",
         // 浏览统计
         browseCount: "--",
      };
   },
   created() {
      this.getBrowseCount();
   },
   methods: {
      /**
       * 获取浏览统计
       */
      getBrowseCount() {
         http.get("site/browseCount").then((res) => {
            this.browseCount = res.data;
         });
      },
      btnSearch() {
         this.$router.replace({ name: "search", params: { k: this.k } });
      },
   },
};
</script>

<style lang="scss" scoped>
.layout {
   display: flex;
   flex-direction: column;
   padding-top: 15px;
   min-height: 100vh;

   &-body {
      position: relative;
      flex: 1 1 auto;
      overflow: hidden;
   }

   &-tool {
      display: flex;
      justify-content: flex-end;
   }

   &-menu {
      display: flex;
      align-items: center;
      background: #f5f3ed;
      border-radius: 4px;

      &__link {
         padding: 3px 8px;
         font-size: 14px;
         color: var(--color-base);
         text-decoration: none;

         &:hover {
            color: var(--color-main);
            text-decoration: underline;
         }
      }

      &__gap {
         font-size: 12px;
         color: #ccc;
      }
   }

   &-head {
      display: flex;
      margin-top: 10px;
   }

   &-logo {
      width: 428px;
      height: 40px;
      background: url(./imgs/logo.png) no-repeat;
   }

   &-nav {
      display: flex;
      align-items: flex-end;
      margin-left: auto;

      &__item {
         margin-left: 50px;
      }

      &__link {
         display: block;
         padding: 6px 8px;
         font-size: 18px;
         color: var(--color-normarl);
         text-decoration: none;

         &:hover,
         &.router-link-active {
            font-weight: 600;
            color: var(--color-main);
            text-decoration: underline;
         }

         &.router-link-active {
            text-decoration: none;
            cursor: default;
         }
      }
   }

   &-bottom {
      padding: 15px 0;
      font-size: 14px;
      text-align: center;
      background: #f5f3ed;

      &__count {
         color: var(--color-fun);
      }

      &__des {
         color: var(--color-fun);
      }

      &__des a {
         text-decoration: none;
         font-size: 13px;
         color: var(--color-fun);
      }
      &__des a img {
         margin-left: 2em;
         width: 1em;
      }
      &__warn {
         // color: hsl(38, 55%, 60%);
         font-size: 18px;
         font-weight: 600;
         color: red;
      }
   }

   &-link {
      display: flex;
      justify-content: center;
      margin: 8px 0;

      &__item {
         margin: 0 5px;
         border: 1px solid var(--border-color-undertint);
      }

      &__img {
         display: block;
         height: 54px;
         user-select: none;
         -webkit-user-drag: none;
         pointer-events: none;
      }
   }
}
</style>
