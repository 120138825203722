<template>
   <div>
      <com-head-banner :src="banner"></com-head-banner>
      <com-page-main>
         <div class="index">
            <div class="index-item">
               <div class="index-item__head">
                  <com-plate-title class="index-item__head__title">
                     新闻报道
                  </com-plate-title>
               </div>
               <ul>
                  <li
                     :class="current_category_one == 'news' ? 'active' : ''"
                     @click="switchCategory('0-0')"
                  >
                     全部
                  </li>
                  <li
                     :class="current_category_one == 'affairs' ? 'active' : ''"
                     @click="switchCategory('0-1')"
                  >
                     时政要闻
                  </li>
                  <li
                     :class="current_category_one == 'project' ? 'active' : ''"
                     @click="switchCategory('0-2')"
                  >
                     专题报道
                  </li>
               </ul>
               <com-new-list
                  ref="list_one"
                  :url="`post?alias=${current_category_one}&from=home`"
                  size="medium"
                  :page-size="6"
               ></com-new-list>
               <router-link
                  class="index-item__head__more"
                  :to="{
                     name: 'lists',
                     query: { node: 'news', type: current_category_one },
                  }"
               >
                  更多>>
               </router-link>
            </div>

            <div class="index-item">
               <div class="index-item__head">
                  <com-plate-title class="index-item__head__title">
                     通知公告
                  </com-plate-title>
               </div>
               <ul>
                  <li
                     :class="current_category_two == 'notice' ? 'active' : ''"
                     @click="switchCategory('1-0')"
                  >
                     全部
                  </li>
                  <li
                     :class="current_category_two == 'bidding' ? 'active' : ''"
                     @click="switchCategory('1-1')"
                  >
                     招标公告
                  </li>
                  <li
                     :class="
                        current_category_two == 'candidate' ? 'active' : ''
                     "
                     @click="switchCategory('1-2')"
                  >
                     中标候选人
                  </li>
                  <li
                     :class="current_category_two == 'result' ? 'active' : ''"
                     @click="switchCategory('1-3')"
                  >
                     中标结果
                  </li>
               </ul>
               <com-new-list
                  ref="list_two"
                  :url="`post?alias=${current_category_two}`"
                  size="medium"
                  :page-size="6"
                  notice-icon
               ></com-new-list>
               <router-link
                  class="index-item__head__more"
                  :to="{
                     name: 'lists',
                     query: { node: 'notice', type: current_category_two },
                  }"
               >
                  更多>>
               </router-link>
            </div>
         </div>
      </com-page-main>
   </div>
</template>

<script>
import ComNewList from "@/components/NewList";
import ComPageMain from "@/components/PageMain";
import ComHeadBanner from "@/components/HeadBanner";
import ComPlateTitle from "@/components/PlateTitle";

export default {
   name: "PageHome",
   components: {
      ComNewList,
      ComPageMain,
      ComHeadBanner,
      ComPlateTitle,
   },
   data() {
      return {
         // banner
         /* eslint-disable */
         banner: [
            require("@/assets/imgs/banner_for_home_0.jpg"),
         ],
         /* eslint-enable */
         category_arr: [
            ["news", "affairs", "project"],
            ["notice", "bidding", "candidate", "result"],
         ],
         current_category_one: "news",
         current_category_two: "notice",
      };
   },
   methods: {
      switchCategory(e) {
         const switchArr = e.split("-");
         switch (switchArr[0]) {
            case "0":
               this.current_category_one = this.category_arr[0][switchArr[1]];
               this.$refs.list_one.url = `post?alias=${this.current_category_one}`;
               this.$refs.list_one.pageCurrent = 1;
               this.$refs.list_one.getData();
               break;
            case "1":
               this.current_category_two = this.category_arr[1][switchArr[1]];
               this.$refs.list_two.url = `post?alias=${this.current_category_two}`;
               this.$refs.list_two.pageCurrent = 1;
               this.$refs.list_two.getData();
               break;
            default:
               break;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.index {
   display: flex;
   padding: 30px var(--gap);
   background: #fff;
   box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.08);

   &-item {
      flex: 1;
      margin-left: 36px;
      overflow: hidden;

      &:first-child {
         margin-left: 0;
      }

      ul {
         padding: 0;
         list-style-type: none;
      }

      ul li {
         font-size: 12px;
         font-weight: 500;
         color: #fff;
         padding: 5px 12px;
         margin-right: 8px;
         background-color: #a7a7a7;
         display: inline;
         border-radius: 6px;
         cursor: pointer;
      }

      ul .active {
         background-color: var(--color-main);
      }

      &__head {
         display: flex;
         align-items: flex-end;
         margin-bottom: 10px;

         &__more {
            float: right;
            padding: 4px 6px;
            margin-left: auto;
            font-size: 12px;
            color: #fff;
            line-height: 1;
            background: var(--color-main);
            border-radius: 4px;
         }

         &__title {
            margin-bottom: 0;
         }
      }
   }
}
</style>
