<template>
   <router-view :class="{ 'theme-100': isHome }"></router-view>
</template>

<script>
export default {
   name: "APP",
   computed: {
      isHome() {
         return this.$route.name === "index";
      },
   },
};
</script>

<style lang="scss" scoped>
.theme {
   &-100 {
   }
}
</style>
