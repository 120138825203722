var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"new",style:({ minHeight: _vm.minHeight })},[(_vm.list.length > 0)?_vm._l((_vm.list),function(item,index){return _c('com-new-item',{key:index,attrs:{"title":item.title,"date":item.date,"prefix":_vm.prefix
               ? _vm.prefix
               : _vm.type && _vm.type_uri != item.category.alias
               ? item.category.title
               : '',"prefix_uri":_vm.prefix_uri
               ? _vm.prefix_uri
               : _vm.type && _vm.type_uri != item.category.alias
               ? '/lists/?type=' + item.category.alias
               : '',"size":_vm.size,"show-date":_vm.showDate,"notice-icon":_vm.noticeIcon,"is_external":item.external_url,"path":`/article?id=${item.id}`}})}):_c('div',{staticClass:"new-empty"},[_vm._v(_vm._s(_vm.emptyText))]),(_vm.pagination && _vm.total > _vm.pageSize)?_c('div',{staticClass:"new-pagination"},[_c('el-pagination',{attrs:{"hide-on-single-page":"","current-page":_vm.pageCurrent,"page-size":_vm.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"update:currentPage":function($event){_vm.pageCurrent=$event},"update:current-page":function($event){_vm.pageCurrent=$event},"current-change":function($event){return _vm.getData(_vm.pageCurrent)}}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }