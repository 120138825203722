<template>
   <com-container class="container-main">
      <slot></slot>
   </com-container>
</template>

<script>
import ComContainer from "../Container";

export default {
   name: "ComPageMain",
   components: {
      ComContainer,
   },
};
</script>

<style lang="scss" scoped>
.container-main {
   padding: 60px 0;
}
</style>
