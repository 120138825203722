var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('com-head-banner',{attrs:{"src":_vm.banner}}),_c('com-page-main',[_c('div',{staticClass:"index"},[_c('div',{staticClass:"index-item"},[_c('div',{staticClass:"index-item__head"},[_c('com-plate-title',{staticClass:"index-item__head__title"},[_vm._v(" 新闻报道 ")])],1),_c('ul',[_c('li',{class:_vm.current_category_one == 'news' ? 'active' : '',on:{"click":function($event){return _vm.switchCategory('0-0')}}},[_vm._v(" 全部 ")]),_c('li',{class:_vm.current_category_one == 'affairs' ? 'active' : '',on:{"click":function($event){return _vm.switchCategory('0-1')}}},[_vm._v(" 时政要闻 ")]),_c('li',{class:_vm.current_category_one == 'project' ? 'active' : '',on:{"click":function($event){return _vm.switchCategory('0-2')}}},[_vm._v(" 专题报道 ")])]),_c('com-new-list',{ref:"list_one",attrs:{"url":`post?alias=${_vm.current_category_one}&from=home`,"size":"medium","page-size":6}}),_c('router-link',{staticClass:"index-item__head__more",attrs:{"to":{
                  name: 'lists',
                  query: { node: 'news', type: _vm.current_category_one },
               }}},[_vm._v(" 更多>> ")])],1),_c('div',{staticClass:"index-item"},[_c('div',{staticClass:"index-item__head"},[_c('com-plate-title',{staticClass:"index-item__head__title"},[_vm._v(" 通知公告 ")])],1),_c('ul',[_c('li',{class:_vm.current_category_two == 'notice' ? 'active' : '',on:{"click":function($event){return _vm.switchCategory('1-0')}}},[_vm._v(" 全部 ")]),_c('li',{class:_vm.current_category_two == 'bidding' ? 'active' : '',on:{"click":function($event){return _vm.switchCategory('1-1')}}},[_vm._v(" 招标公告 ")]),_c('li',{class:_vm.current_category_two == 'candidate' ? 'active' : '',on:{"click":function($event){return _vm.switchCategory('1-2')}}},[_vm._v(" 中标候选人 ")]),_c('li',{class:_vm.current_category_two == 'result' ? 'active' : '',on:{"click":function($event){return _vm.switchCategory('1-3')}}},[_vm._v(" 中标结果 ")])]),_c('com-new-list',{ref:"list_two",attrs:{"url":`post?alias=${_vm.current_category_two}`,"size":"medium","page-size":6,"notice-icon":""}}),_c('router-link',{staticClass:"index-item__head__more",attrs:{"to":{
                  name: 'lists',
                  query: { node: 'notice', type: _vm.current_category_two },
               }}},[_vm._v(" 更多>> ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }