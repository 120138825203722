<template>
   <div
      class="new-item"
      :class="[{ 'new-item--notice': noticeIcon }, `new-item--${size}`]"
   >
      <div class="new-item__title">
         <span v-if="prefix">
            【
            <router-link :to="prefix_uri" class="new-item__link">
               {{ prefix }}
            </router-link>
            】
         </span>
         <router-link
            v-if="!is_external"
            class="new-item__link"
            :to="path"
            target="_blank"
         >
            <slot v-if="'title' in $slots" name="title"></slot>
            <template><div v-html="title"></div></template>
         </router-link>
         <a
            v-if="is_external"
            class="new-item__link"
            :href="is_external"
            target="_blank"
         >
            <slot v-if="'title' in $slots" name="title"></slot>
            <template><div v-html="title"></div></template>
         </a>
      </div>

      <div v-if="showDate" class="new-item__date">
         <slot name="date"></slot>
         <slot v-if="'date' in $slots" name="title"></slot>
         <template>{{ date }}</template>
      </div>
   </div>
</template>

<script>
export default {
   name: "ComNewItem",
   props: {
      // 路劲
      path: {
         type: String,
         required: true,
      },
      is_external: {
         type: String,
         defualt: "",
      },
      // 标题
      title: {
         type: String,
         default: "",
      },
      // 日期
      date: {
         type: String,
         default: "",
      },
      // 大小
      size: {
         type: String,
         default: "small",
      },
      // 前缀
      prefix: {
         type: String,
         default: "",
      },
      // 前缀uri
      prefix_uri: {
         type: String,
         default: "",
      },
      // 显示date
      showDate: Boolean,
      // 通知图标
      noticeIcon: Boolean,
   },
};
</script>

<style lang="scss" scoped>
.new {
   &-item {
      display: flex;
      font-size: 14px;
      padding: 0.5em 0;
      border-bottom: 1px solid var(--border-color-undertint);

      &--mini {
         font-size: 12px;
         padding: 0.5em 0;
      }

      &--small {
         font-size: 14px;
         padding: 0.5em 0;
      }

      &--medium {
         font-size: 14px;
         padding: 0.8em 0;
      }

      &--notice {
         padding-left: 1.8em;
         background: url(./imgs/notice_icon.png) 0 center / auto 1em no-repeat;
      }

      &__title {
         flex: 1;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         span {
            float: left;
         }
      }

      &__link {
         color: var(--color-normarl);
         text-decoration: none;

         &:hover {
            color: var(--color-main);
            text-decoration: underline;
         }
      }
   }
}
</style>
