import Vue from "vue";
import App from "./App";

// router
import router from "./router";

// css
import "./assets/css";

// elment-ui
import "./plugins/element-ui";

// mock
// import "./mock";
// 关闭提示
Vue.config.productionTip = false;

new Vue({
   router,
   render: (h) => h(App),
}).$mount("#app");
