<template>
   <div class="container">
      <slot></slot>
   </div>
</template>

<script>
export default {
   name: "ComContainer",
};
</script>

<style lang="scss" scoped>
.container {
   width: var(--page-width);
   margin: 0 auto;
}
</style>
